<template>
    <div class="app-container">
        <div class="filter-container">
            <div style="margin-bottom:20px;color: red;">*注意：商家必开启收单，分账成员可关闭收单，默认开启收单</div>

            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">添加</el-button>

            <el-input v-model="listQuery.merchant_name" placeholder="商户名称" size="small"  class="filter-item" style="width: 220px;" clearable/>

            <el-select v-model="listQuery.accept_order" placeholder="是否收单" size="small" class="filter-item" style="width: 120px" clearable>
                <el-option label="否" :value="0" />
                <el-option label="是" :value="1" />
            </el-select>

            <el-select v-model="listQuery.account_status" placeholder="账户状态" size="small" class="filter-item" style="width: 120px" clearable>
                <el-option label="未开通" :value="0" />
                <el-option label="已开通" :value="1" />
                <el-option label="冻结" :value="2" />
                <el-option label="注销" :value="3" />
                <el-option label="止付" :value="4" />
                <el-option label="禁止入金" :value="5" />
            </el-select>

            <el-select v-model="listQuery.audit_status" placeholder="审核状态" size="small" class="filter-item" style="width: 120px" clearable>
                <el-option label="未提审" :value="0" />
                <el-option label="待审核" :value="1" />
                <el-option label="已通过" :value="2" />
                <el-option label="已驳回" :value="3" />
            </el-select>

            <el-select v-model="listQuery.is_on" placeholder="上下架状态" size="small" class="filter-item" style="width: 120px" clearable>
                <el-option label="下架" :value="0" />
                <el-option label="上架" :value="1" />
            </el-select>

            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="ID" align="center" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="商户编号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.acq_mer_id }}
                </template>
            </el-table-column>

            <el-table-column label="商户名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.merchant_name }}
                </template>
            </el-table-column>

            <el-table-column label="是否收单" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.accept_order === 1" type="success">是</el-tag>
                    <el-tag v-else-if="scope.row.accept_order === 0" type="danger">否</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="联系人姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.contact_person }}
                </template>
            </el-table-column>

            <el-table-column label="联系人手机号码" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.contact_phone }}
                </template>
            </el-table-column>

            <el-table-column label="商户简称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.short_name }}
                </template>
            </el-table-column>

            <el-table-column label="主体类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.merchant_type === 1" type="primary">个体工商户</el-tag>
                    <el-tag v-else-if="scope.row.merchant_type === 2" type="warning">企业</el-tag>
                    <el-tag v-else-if="scope.row.merchant_type === 3" type="success">个人(小微)</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="账户状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.account_status === 0" type="info">未开通</el-tag>
                    <el-tag v-else-if="scope.row.account_status === 1" type="success">已开通</el-tag>
                    <el-tag v-else-if="scope.row.account_status === 2" type="warning">冻结</el-tag>
                    <el-tag v-else-if="scope.row.account_status === 3" type="danger">注销</el-tag>
                    <el-tag v-else-if="scope.row.account_status === 4" type="primary">止付</el-tag>
                    <el-tag v-else-if="scope.row.account_status === 5" type="danger">禁止入金</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="审核状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.audit_status === 0">未提审</el-tag>
                    <el-tag type="info" v-else-if="scope.row.audit_status === 1">待审核</el-tag>
                    <el-tag type="success" v-else-if="scope.row.audit_status === 2">已通过</el-tag>
                    <template v-else-if="scope.row.audit_status === 3">
                        <el-tag type="danger">已驳回</el-tag>
                        <div>原因:{{scope.row.audit_msg}}</div>
                    </template>
                </template>
            </el-table-column>

            <el-table-column label="上下架状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_on" type="success">上架</el-tag>
                    <el-tag v-else type="danger">下架</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>

                    <el-button type="warning" size="mini" @click="handleBusiness(scope.row)">开通业务</el-button>

                    <el-button type="success" size="mini" @click="handleWithdrawal(scope.row)">申请提现</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="right" label-width="150px">
                <div style="font-size:12px;color:red;margin-bottom:20px;">*注意：商家必开启收单，分账成员可关闭收单，可见字段即必填</div>

                <el-form-item label="商户名称" prop="merchant_name">
                    <el-input type="text" v-model="form.merchant_name" maxlength="100" show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="是否收单" prop="accept_order">
                    <el-switch v-model="form.accept_order" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" :disabled="!!form.id"></el-switch>
                </el-form-item>

                <el-form-item label="联系人姓名" prop="contact_person">
                    <el-input type="text" v-model="form.contact_person" maxlength="40" show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="联系人手机号码" prop="contact_phone">
                    <el-input type="text" v-model="form.contact_phone" maxlength="15" show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="商户简称" prop="short_name">
                    <el-input type="text" v-model="form.short_name" maxlength="20" show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="主体类型" prop="merchant_type">
                    <el-radio-group v-model="form.merchant_type" :disabled="!!form.id">
                        <el-radio :label="1">个体工商户</el-radio>
                        <el-radio :label="2">企业</el-radio>
                        <el-radio :label="3">个人(小微)</el-radio>
                    </el-radio-group>
                </el-form-item>

                <template v-if="form.merchant_type===1 || form.merchant_type===2">
                    <div style="margin:10px 0;width:100%;border-top:1px dashed red;"></div>

                    <el-form-item label="证照类型" prop="business_license_type">
                        <el-radio-group v-model="form.business_license_type">
                            <el-radio :label="1">营业执照</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="证照编号" prop="business_license_code">
                        <el-input type="text" v-model="form.business_license_code" maxlength="20" show-word-limit clearable/>
                    </el-form-item>

                    <el-form-item label="证照商户名称" prop="business_license_name">
                        <el-input type="text" v-model="form.business_license_name" maxlength="100" show-word-limit clearable/>
                    </el-form-item>

                    <el-form-item label="证照图片" prop="business_license_photo">
                        <upload-one v-model="form.business_license_photo"></upload-one>
                    </el-form-item>

                    <el-form-item label="证照有效期(起始)" prop="business_license_from">
                        <el-input type="text" v-model="form.business_license_from" maxlength="10" show-word-limit clearable/>
                        <div style="font-size:12px;color:red;">
                            *格式：20230101
                        </div>
                    </el-form-item>

                    <el-form-item label="证照有效期(截止)" prop="business_license_to">
                        <el-input type="text" v-model="form.business_license_to" maxlength="10" show-word-limit clearable/>
                        <div style="font-size:12px;color:red;">
                            *格式：20230101/长期
                        </div>
                    </el-form-item>

                    <el-form-item label="法人姓名" prop="lawyer_name">
                        <el-input type="text" v-model="form.lawyer_name" maxlength="40" show-word-limit clearable/>
                    </el-form-item>

                    <el-form-item label="经营范围" prop="business_scope">
                        <el-input type="textarea" v-model="form.business_scope" :rows="4" maxlength="2000" show-word-limit clearable/>
                    </el-form-item>

                    <el-form-item label="注册地址" prop="register_address">
                        <el-input type="text" v-model="form.register_address" maxlength="200" show-word-limit clearable/>
                    </el-form-item>
                </template>

                <div style="margin:10px 0;width:100%;border-top:1px dashed red;"></div>

                <el-form-item label="证件类型" prop="lawyer_cert_type">
                    <el-radio-group v-model="form.lawyer_cert_type">
                        <el-radio :label="0">身份证</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="证件号码" prop="lawyer_cert_no">
                    <el-input type="text" v-model="form.lawyer_cert_no" maxlength="40" show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="证件正面照(人像面)" prop="lawyer_cert_photo_front">
                    <upload-one v-model="form.lawyer_cert_photo_front"></upload-one>
                </el-form-item>

                <el-form-item label="证件背面照(国徽面)" prop="lawyer_cert_photo_back">
                    <upload-one v-model="form.lawyer_cert_photo_back"></upload-one>
                </el-form-item>

                <el-form-item label="证件人姓名" prop="certificate_name">
                    <el-input type="text" v-model="form.certificate_name" maxlength="50" show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="证件有效期(起始)" prop="certificate_from">
                    <el-input type="text" v-model="form.certificate_from" maxlength="10" show-word-limit clearable/>
                    <div style="font-size:12px;color:red;">
                        *格式：20230101
                    </div>
                </el-form-item>

                <el-form-item label="证件有效期(截止)" prop="certificate_to">
                    <el-input type="text" v-model="form.certificate_to" maxlength="10" show-word-limit clearable/>
                    <div style="font-size:12px;color:red;">
                        *格式：20230101/长期
                    </div>
                </el-form-item>

                <el-form-item label="法人手机号码" prop="legal_person_phone">
                    <el-input type="text" v-model="form.legal_person_phone" maxlength="15" show-word-limit clearable/>
                </el-form-item>

                <template v-if="form.accept_order===1">
                    <el-form-item label="法人证件详细地址" prop="lawyer_address">
                        <el-input type="text" v-model="form.lawyer_address" minlength="10" maxlength="80" show-word-limit clearable/>
                        <div style="font-size:12px;color:red;">
                            *长度10-80
                        </div>
                    </el-form-item>
                </template>

                <div style="margin:10px 0 20px;width:100%;border-top:1px dashed red;"></div>

                <el-form-item label="开户许可证账户名" prop="licence_account">
                    <el-input type="text" v-model="form.licence_account" maxlength="40" show-word-limit clearable/>
                </el-form-item>

                <template v-if="form.merchant_type===2">
                    <el-form-item label="账号" prop="licence_account_no">
                        <el-input type="text" v-model="form.licence_account_no" maxlength="40" show-word-limit clearable/>
                    </el-form-item>

                    <el-form-item label="开户银行" prop="licence_open_bank">
                        <el-select v-model="form.licence_open_bank" filterable style="width:100%" clearable>
                            <el-option v-for="(item,index) in open_banks" :key="index" :label="item.open_bank" :value="item.open_bank"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="开户支行" prop="licence_open_sub_bank">
                        <el-select v-model="form.licence_open_sub_bank" :filter-method="filterLicenceOpenSubBanks" filterable style="width:100%" clearable>
                            <el-option v-for="(item) in licence_open_sub_banks_list" :key="item.open_bank_code" :label="item.open_sub_bank" :value="item.open_sub_bank"></el-option>
                        </el-select>
                        <div style="font-size:12px;color:red;">
                            *仅展示前{{licence_open_sub_banks_limit}}，可搜索查询
                        </div>
                    </el-form-item>

                    <el-form-item label="开户许可证照片" prop="opening_license_account_photo">
                        <upload-one v-model="form.opening_license_account_photo"></upload-one>
                    </el-form-item>
                </template>

                <div style="margin:10px;width:100%;border-top:1px dashed red;"></div>

                <el-form-item label="结算方式" prop="settle_target">
                    <el-radio-group v-model="form.settle_target">
                        <el-radio :label="1">自动提现</el-radio>
                        <el-radio :label="2">手动提现</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="结算账户类型" prop="settle_account_type">
                    <el-radio-group v-model="form.settle_account_type">
                        <el-radio :label="1" :disabled="form.merchant_type===3">对公账户</el-radio>
                        <el-radio :label="2">法人账户</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="结算账户号" prop="settle_account_no">
                    <el-input type="text" v-model="form.settle_account_no" maxlength="40" show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="结算账户名" prop="settle_account">
                    <el-input type="text" v-model="form.settle_account" maxlength="40" show-word-limit clearable :disabled="!!form.id"/>
                </el-form-item>

                <el-form-item label="开户银行" prop="open_bank">
                    <el-select v-model="form.open_bank" filterable style="width:100%" clearable>
                        <el-option v-for="(item,index) in open_banks" :key="index" :label="item.open_bank" :value="item.open_bank"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="开户支行" prop="open_sub_bank">
                    <el-select v-model="form.open_sub_bank" :filter-method="filterSettleOpenSubBanks" filterable style="width:100%" clearable>
                        <el-option v-for="(item) in settle_open_sub_banks_list" :key="item.open_bank_code" :label="item.open_sub_bank" :value="item.open_sub_bank"></el-option>
                    </el-select>
                    <div style="font-size:12px;color:red;">
                        *仅展示前{{settle_open_sub_banks_limit}}，可搜索查询
                    </div>
                </el-form-item>

                <el-form-item label="银行卡正面照" prop="bank_card_photo_front">
                    <upload-one v-model="form.bank_card_photo_front"></upload-one>
                </el-form-item>

                <div style="margin:10px 0 20px;width:100%;border-top:1px dashed red;"></div>

                <template v-if="form.accept_order===1">
                    <el-form-item label="平台商户微信渠道号" prop="wechat_channel_id">
                        <el-input type="text" v-model="form.wechat_channel_id" maxlength="30" show-word-limit clearable disabled/>
                        <div style="font-size:12px;color:red;">
                            *填写：181342925
                        </div>
                    </el-form-item>

                    <el-form-item label="微信经营类目ID" prop="wechat_business_id">
                        <el-input type="text" v-model="form.wechat_business_id" maxlength="30" show-word-limit clearable disabled/>
                        <div style="font-size:12px;color:red;">
                            *填写：758
                        </div>
                    </el-form-item>

                    <el-form-item label="银联快捷简称" prop="union_short_name">
                        <el-input type="text" v-model="form.union_short_name" maxlength="16" show-word-limit clearable/>
                        <div style="font-size:12px;color:red;">
                            *请与商户简称一致
                        </div>
                    </el-form-item>

                    <el-form-item label="客服电话" prop="service_tel">
                        <el-input type="text" v-model="form.service_tel" maxlength="15" show-word-limit clearable/>
                    </el-form-item>

                    <el-form-item label="邮箱地址" prop="email">
                        <el-input type="text" v-model="form.email" maxlength="40" show-word-limit clearable/>
                    </el-form-item>

                    <el-form-item label="网站或APP名称" prop="website_or_app_name">
                        <el-input type="text" v-model="form.website_or_app_name" maxlength="128" show-word-limit clearable disabled/>
                        <div style="font-size:12px;color:red;">
                            *填写：味美美校园外卖
                        </div>
                    </el-form-item>

                    <el-form-item label="经营地址所在省" prop="province">
                        <el-select v-model="form.province" filterable style="width:100%" clearable>
                            <el-option v-for="(item,index) in provinces" :key="index" :label="item.name" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="经营地址所在市" prop="city">
                        <el-select v-model="form.city" filterable style="width:100%" clearable>
                            <el-option v-for="(item,index) in cities" :key="index" :label="item.name" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="经营地址所在区" prop="area">
                        <el-select v-model="form.area" filterable style="width:100%" clearable>
                            <el-option v-for="(item,index) in areas" :key="index" :label="item.name" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="经营详细地址" prop="business_address">
                        <el-input type="text" v-model="form.business_address" minlength="10" maxlength="80" show-word-limit clearable/>
                        <div style="font-size:12px;color:red;">
                            *长度10-80
                        </div>
                    </el-form-item>

                    <el-form-item label="商户类别MCC码" prop="mcc">
                        <el-radio-group v-model="form.mcc">
                            <div style="margin:10px 0;"><el-radio label="5311">百货商店</el-radio></div>
                            <div style="margin:10px 0;"><el-radio label="5331">各类杂货店、便利店</el-radio></div>
                            <div style="margin:10px 0;"><el-radio label="5411">大型仓储式超级市场，包含“超市、仓储式大卖场、连锁店、便利店”等字样</el-radio></div>
                            <div style="margin:10px 0;"><el-radio label="5812">餐饮，营业面积100㎡以上</el-radio></div>
                            <div style="margin:10px 0;"><el-radio label="5814">餐饮，营业面积100㎡(含)以下</el-radio></div>

                            <div style="margin:10px 0;"><el-radio label="8999">其他专业服务</el-radio></div>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="门店门头照" prop="store_head_photo">
                        <upload-one v-model="form.store_head_photo"></upload-one>
                    </el-form-item>

                    <el-form-item label="门店内景照" prop="store_hall_photo">
                        <upload-one v-model="form.store_hall_photo"></upload-one>
                    </el-form-item>

                    <el-form-item label="其他附件" prop="other_attachment">
                        <upload-one v-model="form.other_attachment" style="width: 100%;height: 40px;"></upload-one>
                        <div style="font-size:12px;color:red;">
                            *需上传压缩包文件(格式后缀：rar、zip)，请点击下载<a href="/其他附件.zip" download="其他附件.zip" style="color:#409EFF;">【示例】</a>对其内容进行替换后上传
                        </div>
                    </el-form-item>

                    <el-form-item label="备注" prop="remarks">
                        <el-input type="text" v-model="form.remarks" maxlength="100" show-word-limit clearable disabled/>
                        <div style="font-size:12px;color:red;">
                            *填写：该商户为平台商户（校园外卖业务）入驻子商户，相关材料请见附件压缩包
                        </div>
                    </el-form-item>
                </template>

                <el-form-item label="注册资本" prop="registered_capital">
                    <el-input-number v-model="form.registered_capital" :min="0" :precision="0"></el-input-number>
                    <div style="font-size:12px;color:red;">
                        *营业执照上未标注注册资本的填0
                    </div>
                </el-form-item>

                <el-form-item label="上下架状态" prop="is_on">
                    <el-switch v-model="form.is_on" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <div style="font-size:12px;color:red;">
                        *只修改【上下架状态】，则【仅保存】即可，无需【提审并保存】
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData(0)" :loading="btnLoading">仅保存</el-button>
                <el-button type="primary" @click="saveData(1)" :loading="btnLoading">提审并保存</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import openBanks from "@/assets/epaylinks/open_bank.json";
    import openSubBanks from "@/assets/epaylinks/open_sub_bank.json";
    import areaCodes from "@/assets/epaylinks/area_code.json";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    merchant_name: '',
                    accept_order: '',
                    account_status: '',
                    audit_status: '',
                    is_on: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '编辑',
                    create: '添加',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    merchant_name: '',
                    accept_order: 1,
                    contact_person: '',
                    contact_phone: '',
                    short_name: '',
                    merchant_type: 1,
                    business_license_type: null,
                    business_license_code: '',
                    business_license_name: '',
                    business_license_photo: '',
                    business_license_from: '',
                    business_license_to: '',
                    lawyer_name: '',
                    business_scope: '',
                    register_address: '',
                    lawyer_cert_type: 0,
                    lawyer_cert_no: '',
                    lawyer_cert_photo_front: '',
                    lawyer_cert_photo_back: '',
                    certificate_name: '',
                    certificate_from: '',
                    certificate_to: '',
                    legal_person_phone: '',
                    licence_account: '',
                    licence_account_no: '',
                    licence_open_bank: '',
                    licence_open_sub_bank: '',
                    opening_license_account_photo: '',
                    settle_target: 2,
                    settle_account_type: 2,
                    settle_account_no: '',
                    settle_account: '',
                    open_bank: '',
                    open_sub_bank: '',
                    open_bank_code: '',
                    bank_card_photo_front: '',
                    wechat_channel_id: '',
                    wechat_business_id: '',
                    union_short_name: '',
                    service_tel: '',
                    email: '',
                    website_or_app_name: '',
                    province: '',
                    city: '',
                    area: '',
                    business_address: '',
                    mcc: '',
                    store_head_photo: '',
                    store_hall_photo: '',
                    other_attachment: '',
                    registered_capital: 0,
                    remarks: '',
                    lawyer_address: '',
                    is_on: 1,
                    is_audit: '',
                },
                rules: {
                    merchant_name: [{ required: true, message: '商户名称不能为空', trigger: 'change' }],
                    accept_order: [{ required: true, message: '是否收单不能为空', trigger: 'change' }],
                    contact_person: [{ required: true, message: '联系人姓名不能为空', trigger: 'change' }],
                    contact_phone: [{ required: true, message: '联系人手机号码不能为空', trigger: 'change' }],
                    short_name: [{ required: true, message: '商户简称不能为空', trigger: 'change' }],
                    merchant_type: [{ required: true, message: '主体类型不能为空', trigger: 'change' }],
                    lawyer_cert_type: [{ required: true, message: '证件类型不能为空', trigger: 'change' }],
                    lawyer_cert_no: [{ required: true, message: '证件号码不能为空', trigger: 'change' }],
                    lawyer_cert_photo_front: [{ required: true, message: '证件正面照不能为空', trigger: 'change' }],
                    lawyer_cert_photo_back: [{ required: true, message: '证件背面照不能为空', trigger: 'change' }],
                    certificate_name: [{ required: true, message: '证件人姓名不能为空', trigger: 'change' }],
                    certificate_from: [{ required: true, message: '证件有效期(起始)不能为空', trigger: 'change' }],
                    certificate_to: [{ required: true, message: '证件有效期(截止)不能为空', trigger: 'change' }],
                    legal_person_phone: [{ required: true, message: '法人手机号码不能为空', trigger: 'change' }],
                    licence_account: [{ required: true, message: '开户许可证账户名不能为空', trigger: 'change' }],
                    settle_target: [{ required: true, message: '结算方式不能为空', trigger: 'change' }],
                    settle_account_type: [{ required: true, message: '结算账户类型不能为空', trigger: 'change' }],
                    settle_account_no: [{ required: true, message: '结算账户号不能为空', trigger: 'change' }],
                    settle_account: [{ required: true, message: '结算账户名不能为空', trigger: 'change' }],
                    open_bank: [{ required: true, message: '开户银行不能为空', trigger: 'change' }],
                    open_sub_bank: [{ required: true, message: '开户支行不能为空', trigger: 'change' }],
                    bank_card_photo_front: [{ required: true, message: '银行卡正面照不能为空', trigger: 'change' }],
                    registered_capital: [{ required: true, message: '注册资本不能为空', trigger: 'change' }],
                    is_on: [{ required: true, message: "上下架状态不能为空", trigger: "change" }],
                },
                // 开户银行
                open_banks: [],
                // 开户支行
                open_sub_banks: [],
                // 许可证开户支行
                licence_open_sub_banks: [],
                licence_open_sub_banks_limit: 500,
                licence_open_sub_banks_list: [],
                // 结算开户支行
                settle_open_sub_banks: [],
                settle_open_sub_banks_limit: 500,
                settle_open_sub_banks_list: [],
                // 地区编码
                area_codes: [],
                provinces: [],
                cities: [],
                areas: [],
                // 编辑时监听联动无需置空
                watchResetting: true,
            };
        },
        created() {
            this.open_banks = openBanks;
            this.open_sub_banks = openSubBanks;
            this.area_codes = areaCodes;
            this.initProvinces();
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
            "form.accept_order": {
                handler(newValue, oldValue) {
                    if (newValue === 1) {
                        this.form.wechat_channel_id = '181342925';
                        this.form.wechat_business_id = '758';
                        this.form.website_or_app_name = '味美美校园外卖';
                        this.form.remarks = '该商户为平台商户（校园外卖业务）入驻子商户，相关材料请见附件压缩包';
                    } else {
                        this.form.wechat_channel_id = '';
                        this.form.wechat_business_id = '';
                        this.form.website_or_app_name = '';
                        this.form.remarks = '';
                    }
                },
                deep: true
            },
            "form.licence_open_bank": {
                handler(newValue, oldValue) {
                    this.licence_open_sub_banks = [];
                    this.licence_open_sub_banks_list = [];
                    if (oldValue && newValue !== oldValue && this.watchResetting) {
                        this.form.licence_open_sub_bank = '';
                    }
                    if (newValue) {
                        this.licence_open_sub_banks = this.open_sub_banks.filter(item => item.open_sub_bank.includes(newValue));
                        this.licence_open_sub_banks_list = this.licence_open_sub_banks.length >= this.licence_open_sub_banks_limit ? this.licence_open_sub_banks.slice(0, this.licence_open_sub_banks_limit) : this.licence_open_sub_banks;
                    }
                },
                deep: true
            },
            "form.open_bank": {
                handler(newValue, oldValue) {
                    this.settle_open_sub_banks = [];
                    this.settle_open_sub_banks_list = [];
                    if (oldValue && newValue !== oldValue && this.watchResetting) {
                        this.form.open_sub_bank = '';
                    }
                    if (newValue) {
                        this.settle_open_sub_banks = this.open_sub_banks.filter(item => item.open_sub_bank.includes(newValue));
                        this.settle_open_sub_banks_list = this.settle_open_sub_banks.length >= this.settle_open_sub_banks_limit ? this.settle_open_sub_banks.slice(0, this.settle_open_sub_banks_limit) : this.settle_open_sub_banks;
                    }
                },
                deep: true
            },
            "form.open_sub_bank": {
                handler(newValue, oldValue) {
                    if (newValue) {
                        let item = this.settle_open_sub_banks.find(item => item.open_sub_bank === newValue);
                        this.form.open_bank_code = item ? item.open_bank_code : '';
                    } else {
                        this.form.open_bank_code = '';
                    }
                },
                deep: true
            },
            "form.province": {
                handler(newValue, oldValue) {
                    this.cities = [];
                    if (oldValue && newValue !== oldValue && this.watchResetting) {
                        this.form.city = '';
                    }
                    if (newValue) {
                        this.cities = this.area_codes.filter(item => item.level==='2'&&item.parent_code===newValue);
                    }
                },
                deep: true
            },
            "form.city": {
                handler(newValue, oldValue) {
                    this.areas = [];
                    if (oldValue && newValue !== oldValue && this.watchResetting) {
                        this.form.area = '';
                    }
                    if (newValue) {
                        this.areas = this.area_codes.filter(item => item.level==='3'&&item.parent_code===newValue);
                    }
                },
                deep: true
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/merchant/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    merchant_name: '',
                    accept_order: 1,
                    contact_person: '',
                    contact_phone: '',
                    short_name: '',
                    merchant_type: 1,
                    business_license_type: null,
                    business_license_code: '',
                    business_license_name: '',
                    business_license_photo: '',
                    business_license_from: '',
                    business_license_to: '',
                    lawyer_name: '',
                    business_scope: '',
                    register_address: '',
                    lawyer_cert_type: 0,
                    lawyer_cert_no: '',
                    lawyer_cert_photo_front: '',
                    lawyer_cert_photo_back: '',
                    certificate_name: '',
                    certificate_from: '',
                    certificate_to: '',
                    legal_person_phone: '',
                    licence_account: '',
                    licence_account_no: '',
                    licence_open_bank: '',
                    licence_open_sub_bank: '',
                    opening_license_account_photo: '',
                    settle_target: 2,
                    settle_account_type: 2,
                    settle_account_no: '',
                    settle_account: '',
                    open_bank: '',
                    open_sub_bank: '',
                    open_bank_code: '',
                    bank_card_photo_front: '',
                    wechat_channel_id: '',
                    wechat_business_id: '',
                    union_short_name: '',
                    service_tel: '',
                    email: '',
                    website_or_app_name: '',
                    province: '',
                    city: '',
                    area: '',
                    business_address: '',
                    mcc: '',
                    store_head_photo: '',
                    store_hall_photo: '',
                    other_attachment: '',
                    registered_capital: 0,
                    remarks: '',
                    lawyer_address: '',
                    is_on: 1,
                    is_audit: '',
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            handleUpdate(row) {
                this.resetForm()
                this.watchResetting = false
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.watchResetting = true
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData(is_audit) {
                this.form.is_audit = is_audit;

                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                if (this.form.merchant_type===1 || this.form.merchant_type===2) {
                    if (!this.form.business_license_type) {
                        this.$message({
                            type: "warning",
                            message: "证照类型不能为空"
                        });
                        return;
                    }

                    if (!this.form.business_license_code) {
                        this.$message({
                            type: "warning",
                            message: "证照编号不能为空"
                        });
                        return;
                    }

                    if (!this.form.business_license_name) {
                        this.$message({
                            type: "warning",
                            message: "证照商户名称不能为空"
                        });
                        return;
                    }

                    if (!this.form.business_license_photo) {
                        this.$message({
                            type: "warning",
                            message: "证照图片不能为空"
                        });
                        return;
                    }

                    if (!this.form.business_license_from) {
                        this.$message({
                            type: "warning",
                            message: "证照有效期(起始)不能为空"
                        });
                        return;
                    }

                    if (!this.form.business_license_to) {
                        this.$message({
                            type: "warning",
                            message: "证照有效期(截止)不能为空"
                        });
                        return;
                    }

                    if (!this.form.lawyer_name) {
                        this.$message({
                            type: "warning",
                            message: "法人姓名不能为空"
                        });
                        return;
                    }

                    if (!this.form.business_scope) {
                        this.$message({
                            type: "warning",
                            message: "经营范围不能为空"
                        });
                        return;
                    }

                    if (!this.form.register_address) {
                        this.$message({
                            type: "warning",
                            message: "注册地址不能为空"
                        });
                        return;
                    }
                }

                if (this.form.accept_order===1 && !this.form.lawyer_address) {
                    this.$message({
                        type: "warning",
                        message: "法人证件详细地址不能为空"
                    });
                    return;
                }

                if (this.form.merchant_type===2) {
                    if (!this.form.licence_account_no) {
                        this.$message({
                            type: "warning",
                            message: "账号不能为空"
                        });
                        return;
                    }

                    if (!this.form.licence_open_bank) {
                        this.$message({
                            type: "warning",
                            message: "开户银行不能为空"
                        });
                        return;
                    }

                    if (!this.form.licence_open_sub_bank) {
                        this.$message({
                            type: "warning",
                            message: "开户支行不能为空"
                        });
                        return;
                    }

                    if (!this.form.opening_license_account_photo) {
                        this.$message({
                            type: "warning",
                            message: "开户许可证照片不能为空"
                        });
                        return;
                    }
                }

                if (!this.form.open_bank_code) {
                    this.$message({
                        type: "warning",
                        message: "开户行联行号不能为空，请重新选择结算信息的开户支行"
                    });
                    return;
                }

                if (this.form.accept_order===1) {
                    if (!this.form.wechat_channel_id || this.form.wechat_channel_id !== '181342925') {
                        this.$message({
                            type: "warning",
                            message: "平台商户微信渠道号不能为空且只能为181342925"
                        });
                        return;
                    }

                    if (!this.form.wechat_business_id || this.form.wechat_business_id !== '758') {
                        this.$message({
                            type: "warning",
                            message: "微信经营类目ID不能为空且只能为758"
                        });
                        return;
                    }

                    if (!this.form.union_short_name || this.form.union_short_name !== this.form.short_name) {
                        this.$message({
                            type: "warning",
                            message: "银联快捷简称不能为空且需与商户简称一致"
                        });
                        return;
                    }

                    if (!this.form.service_tel) {
                        this.$message({
                            type: "warning",
                            message: "客服电话不能为空"
                        });
                        return;
                    }

                    if (!this.form.email) {
                        this.$message({
                            type: "warning",
                            message: "邮箱地址不能为空"
                        });
                        return;
                    }

                    if (!this.form.website_or_app_name || this.form.website_or_app_name !== '味美美校园外卖') {
                        this.$message({
                            type: "warning",
                            message: "网站或APP名称不能为空且只能为味美美校园外卖"
                        });
                        return;
                    }

                    if (!this.form.province) {
                        this.$message({
                            type: "warning",
                            message: "经营地址所在省不能为空"
                        });
                        return;
                    }

                    if (!this.form.city) {
                        this.$message({
                            type: "warning",
                            message: "经营地址所在市不能为空"
                        });
                        return;
                    }

                    if (!this.form.area) {
                        this.$message({
                            type: "warning",
                            message: "经营地址所在区不能为空"
                        });
                        return;
                    }

                    if (!this.form.business_address) {
                        this.$message({
                            type: "warning",
                            message: "经营详细地址不能为空"
                        });
                        return;
                    }

                    if (!this.form.mcc) {
                        this.$message({
                            type: "warning",
                            message: "商户类别MCC码不能为空"
                        });
                        return;
                    }

                    if (!this.form.store_head_photo) {
                        this.$message({
                            type: "warning",
                            message: "门店门头照不能为空"
                        });
                        return;
                    }

                    if (!this.form.store_hall_photo) {
                        this.$message({
                            type: "warning",
                            message: "门店内景照不能为空"
                        });
                        return;
                    }

                    if (!this.form.store_hall_photo) {
                        this.$message({
                            type: "warning",
                            message: "门店内景照不能为空"
                        });
                        return;
                    }

                    if (!this.form.other_attachment || ['rar','zip'].indexOf(this.form.other_attachment.substring(this.form.other_attachment.lastIndexOf('.')+1)) === -1) {
                        this.$message({
                            type: "warning",
                            message: "其他附件不能为空且为压缩包文件"
                        });
                        return;
                    }

                    if (!this.form.remarks || this.form.remarks !== '该商户为平台商户（校园外卖业务）入驻子商户，相关材料请见附件压缩包') {
                        this.$message({
                            type: "warning",
                            message: "备注不能为空且只能为该商户为平台商户（校园外卖业务）入驻子商户，相关材料请见附件压缩包"
                        });
                        return;
                    }
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/merchant/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            // 开通业务
            handleBusiness(row) {
                this.$router.push(`/merchantBusiness/list?merchant_id=${row.id}`);
            },
            // 申请提现
            handleWithdrawal(row) {
                this.$router.push(`/merchant/withdrawalList?merchant_id=${row.id}`);
            },
            // 过滤许可证开户支行
            filterLicenceOpenSubBanks(query) {
                let list = [];
                if (query) {
                    list = this.licence_open_sub_banks.filter(item => item.open_sub_bank.includes(query));
                } else {
                    list = this.licence_open_sub_banks;
                }
                this.licence_open_sub_banks_list = list.length >= this.licence_open_sub_banks_limit ? list.slice(0, this.licence_open_sub_banks_limit) : list;
            },
            // 过滤结算开户支行
            filterSettleOpenSubBanks(query) {
                let list = [];
                if (query) {
                    list = this.settle_open_sub_banks.filter(item => item.open_sub_bank.includes(query));
                } else {
                    list = this.settle_open_sub_banks;
                }
                this.settle_open_sub_banks_list = list.length >= this.settle_open_sub_banks_limit ? list.slice(0, this.settle_open_sub_banks_limit) : list;
            },
            // 初始化省份
            initProvinces() {
                this.provinces = this.area_codes.filter(item => item.level==='1'&&item.parent_code==='0');
            }
        }
    };
</script>


<style rel="stylesheet/scss" lang="scss" scoped>

</style>
